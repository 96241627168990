import $ from "jquery";

import Account from './Account';
import Banner from './Banner';
import Cash from './Cash';
import Marketing from './Marketing';
import Payout from './Payout';
import Referral from './Referral';
import Loader from './../../components/loader';
import Router from './../../components/router';
import Vars from './../../vars';

if (typeof global.loader === "undefined" || typeof global.loader === undefined){
    global.loader = Loader.init();
}

const app = {
    _elems : {
        $content : $(),
        form     : {
            $auth    : $(),
            $reg     : $(),
            $remind  : $(),
            $restore : $()
        }
    },

    _state : {
        route : {}
    },

    _setTitle : function(title){
        document.title = title + '. ' + Vars.partner.title;
    },

    _initRouter : function() {
        let self = this;

        self._state.router = Router.init({
            routes: [
                {
                    path: /^$|^#$/i,
                    on: function () {
                        const title = 'Привлеченные партнеры и застройщики';
                        self._setTitle(title);

                        new Referral({
                            page : Vars.page.list
                        });
                    }
                },
                {
                    path: /^#referral\/([a-z]+)\/([0-9]+)$/i,
                    on: function (type, id) {
                        const title = (type == 'partner' ? 'Партнер #' : 'Застройщик #') + id;
                        self._setTitle(title);

                        new Referral({
                            page : Vars.page.item,
                            type : type,
                            id   : id
                        });
                    }
                },
                {
                    path: /^#marketing$/i,
                    on: function () {
                        const title = 'Реферальные ссылки';
                        self._setTitle(title);

                        new Marketing({
                            page : Vars.page.index
                        });
                    }
                },
                {
                    path: /^#payout$/i,
                    on: function () {
                        const title = 'Оставить заявку';
                        self._setTitle(title);

                        new Payout({
                            page : Vars.page.form
                        });
                    }
                },
                {
                    path: /^#payouts$/i,
                    on: function () {
                        const title = 'Выплаты';
                        self._setTitle(title);

                        new Payout({
                            page : Vars.page.list
                        });
                    }
                },
                {
                    path: /^#banners$/i,
                    on: function () {
                        const title = 'Баннеры';
                        self._setTitle(title);

                        new Banner({
                            page : Vars.page.index
                        });
                    }
                },
                {
                    path: /^#account$/i,
                    on: function () {
                        const title = 'Личные данные';
                        self._setTitle(title);

                        new Account({
                            page: Vars.page.index
                        });
                    }
                }
            ]
        });
    },

    _bindElements : function() {
        let self = this;
        self._elems.$content = $('.content');

        self._elems.form.$auth    = self._elems.$content.find('#form-auth');
        self._elems.form.$reg     = self._elems.$content.find('#form-reg');
        self._elems.form.$remind  = self._elems.$content.find('#form-remind');
        self._elems.form.$restore = self._elems.$content.find('#form-restore');
    },

    _bindUI : function() {
        let self = this;

        loader.increase();
        $(document).ready(function() {
            loader.decrease();
            if (self._elems.form.$auth.length || self._elems.form.$reg.length || self._elems.form.$remind.length || self._elems.form.$restore.length) {
                new Account();
            }
            else {
                new Cash();
                self._initRouter();
            }
        });
    },

    init : function () {
        let self = this;

        self._bindElements();
        self._bindUI();
    }
};

export default app;