import Vars from '../vars';

class Validation {
    constructor (args) {
        let self = this;

        self._setArgs(args);
        self._init();
    }

    _elems = {
        $_      : $(),
        $input  : $(),
        $select : $()
    };

    _setArgs = (args) => {
        let self = this;

        self._elems.$_ = args.form;
    };

    _bindCheck = (args) => {
        let self = this;

        let $field = args.$field;
        if (!$field.length) return;

        let value = args.value;
        let rules = args.rules;

        let preMatch = '';
        let match    = '';
        let errors   = 'Некорректное значение.';
        if (rules.indexOf('nullable') != -1 && value == '') return;

        if (rules.indexOf('email') != -1){
            match = '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
            errors += ' Укажите корректно email.';
        }

        if (rules.indexOf('domain') != -1){
            match = '^((http|https):\\/\\/)?([a-zA-Z0-9_][-_a-zA-Z0-9]{0,62}\\.)+([a-zA-Z0-9]{1,10})$';
            errors += ' Укажите правильно домен.';
        }

        if (rules.indexOf('yandex.metrika') != -1){
            preMatch = '0-9';
            errors += ' Укажите корректное значение счётчика.';
        }

        if (rules.indexOf('google.analytics') != -1){
            match = '^([A-Z]{2})(\\-)(\\d+)(\\-)(\\d+)$';
            errors += ' Укажите корректное значение счётчика.';
        }

        if (rules.indexOf('lang:en') != -1) {
            preMatch += 'a-zA-Z';
            errors += ' Используйте латиницу.';
        }
        if (rules.indexOf('integer') != -1) {
            preMatch += '0-9';
            errors += ' Используйте цифры';
        }
        if (rules.indexOf('numbers') != -1) {
            preMatch += '0-9';
            errors += ' Используйте цифры';
        }
        if (rules.indexOf('min') != -1) {
            preMatch += '0-9';
            errors += ' Используйте цифры';
        }
        if (rules.indexOf('hyphen') != -1) {
            preMatch += '-';
        }

        for (let key in rules) {
            if (rules[key].indexOf('min:') != -1) {
                let min = parseInt(rules[key].substr('min:'.length));
                if (rules.indexOf('integer') != -1) {
                    if (value < min) {
                        $field.addClass('--error');
                        if (!$field.find('.form-field__error').length) {
                            $field.append(
                                $('<div />')
                                    .addClass('form-field__error')
                            )
                        }
                        $field.children('.form-field__error').text('Минимальное значение: ' + min).show();
                        return;
                    }
                }
                else {
                    if (value.length < min) {
                        $field.addClass('--error');
                        if (!$field.find('.form-field__error').length) {
                            $field.append(
                                $('<div />')
                                    .addClass('form-field__error')
                            )
                        }
                        $field.children('.form-field__error').text('Минимальное количество символов: ' + min).show();
                        return;
                    }
                }
            }
            if (rules[key].indexOf('max:') != -1) {
                let max = parseInt(rules[key].substr('max:'.length));
                if (rules.indexOf('integer') != -1) {
                    if (value > max) {
                        $field.addClass('--error');
                        if (!$field.find('.form-field__error').length) {
                            $field.append(
                                $('<div />')
                                    .addClass('form-field__error')
                            )
                        }
                        $field.children('.form-field__error').text('Максимальное значение: ' + max).show();
                        return;
                    }
                }
                else {
                    if (value.length > max) {
                        $field.addClass('--error');
                        if (!$field.find('.form-field__error').length) {
                            $field.append(
                                $('<div />')
                                    .addClass('form-field__error')
                            )
                        }
                        $field.children('.form-field__error').text('Максимальное количество символов: ' + max).show();
                        return;
                    }
                }
            }
        }
        match = match == '' && preMatch != '' ? '^[' + preMatch + ']+$' : match;

        if (value.match(match) == null){
            $field.addClass('--error');
            if (!$field.find('.form-field__error').length) {
                $field.append(
                    $('<div />')
                        .addClass('form-field__error')
                )
            }
            $field.children('.form-field__error').text(errors).show();
        }
    };

    _bindElements = () => {

        let self = this;

        if (!self._elems.$_.length) return;

        self._elems.$input  = self._elems.$_.find('input');
        self._elems.$select = self._elems.$_.find('select');
    };

    _bindUI = () => {

        let self = this;

        if (!self._elems.$_.length) return;

        self._elems.$input.length && self._elems.$input.keyup(function(){
            if ($(this).attr('required') === 'required' || $(this).parents('.form-field').hasClass('required')) {
                if ($(this).val() != '') {
                    $(this).parents('.form-field').removeClass('--error');
                    $(this).parent().next('.form-field__error').text('');
                } else {
                    $(this).parents('.form-field').addClass('--error');
                    $(this).parent().next('.form-field__error').text('Поле обязательно для заполнения').show();
                }
            }
            if ($(this).parent().hasClass('float')) {
                if ($(this).val().match(/^\d+/) != null || $(this).val() == '') {
                    $(this).parents('.form-field').removeClass('--error');
                    $(this).parent().next('.form-field__error').text('');
                } else {
                    $(this).parents('.form-field').addClass('--error');
                    $(this).parent().next('.form-field__error').text('Поле имеет ошибочный формат').show();
                }
            }
            if ($(this).attr('type') == 'number') {
                if ($(this).val().match(/^\d+/) != null || $(this).val() == '') {
                    $(this).parents('.form-field').removeClass('--error');
                    $(this).parent().next('.form-field__error').text('');
                } else {
                    $(this).parents('.form-field').addClass('--error');
                    $(this).parent().next('.form-field__error').text('Поле имеет ошибочный формат').show();
                }
            }
        });

        self._elems.$select.length && self._elems.$select.change(function(){
            if ($(this).attr('required') === 'required' || $(this).parents('.form-field').hasClass('required')) {
                if ($(this).val() != '') {
                    $(this).parents('.form-field').removeClass('--error');
                    $(this).parent().next('.form-field__error').text('');
                }
            }
        });

        self._elems.$_.find('.form-field[data-rules]').each(function(){
            let $field = $(this);

            let timeout = false;
            const rules = $field.data('rules').split(',');
            $field.find('input, textarea').on('keyup', function(){
                let $input = $(this);

                $field.removeClass('--error');
                $field.children('.form-field__error').text('');

                clearTimeout(timeout);
                timeout = setTimeout(function(){
                    self._bindCheck({
                        $field : $field,
                        value  : $input.val(),
                        rules  : rules
                    });
                    if (!self._elems.$_.find('.form-field.--error').length) {
                        self._elems.$_.find('input[type=submit], button[type=submit]').removeAttr('disabled');
                    }
                    else {
                        self._elems.$_.find('input[type=submit], button[type=submit]').attr('disabled', 'disabled');
                    }
                }, 500);
            });
            $field.find('input, textarea').on('change', function(){
                let $input = $(this);

                $field.removeClass('--error');
                $field.children('.form-field__error').text('');

                clearTimeout(timeout);
                timeout = setTimeout(function(){
                    self._bindCheck({
                        $field : $field,
                        value  : $input.val(),
                        rules  : rules
                    });
                    if (!self._elems.$_.find('.form-field.--error').length) {
                        self._elems.$_.find('input[type=submit], button[type=submit]').removeAttr('disabled');
                    }
                    else {
                        self._elems.$_.find('input[type=submit], button[type=submit]').attr('disabled', 'disabled');
                    }
                }, 500);
            });
        });
    };

    _init = () => {
        let self = this;

        self._bindElements();
        self._bindUI();
    };

    showErrors = (error) => {
        let self = this;

        if (!self._elems.$_.length) return;

        if (error.type === Vars.errors.types.validation){
            self._elems.$_.find('.form-field[data-name]').each(function() {
                $(this).removeClass('--error');
                $(this).find('.form-field__error');
            });

            Object.keys(error.fields).forEach(function(fieldName) {
                self._elems.$_.find('.form-field[data-name=' + fieldName.replace(/\./g, '\\.')  + ']').each(function() {
                    let $field = $(this);
                    $field.addClass('--error');

                    if (!$field.children('.form-field__error').length) {
                        $field.append(
                            $('<div />')
                                .addClass('form-field__error')
                        )
                    }
                    $field.children('.form-field__error').each(function() {
                        $(this).show().html(error.fields[fieldName].join('<br />'));
                    });
                });
            });

            let firstFieldName = Object.keys(error.fields)[0].replace(/\./g, '\\.');
            let $first = self._elems.$_.find('.form-field[data-name=' + firstFieldName + ']').find('input, select');
            $first.focus();

        }
        else {
            let title = 'Ошибка';
            if (error.type === Vars.errors.types.notFound)
                title += '404';
            else if (error.type === Vars.errors.types.permissionDenied)
                title += 'Доступ запрещен';
        }
    }
}

export default Validation;