import DropzoneLib from 'dropzone';

import Modal from "./../../components/Modal";
import Utils from './../../components/utils';

class Dropzone {

    constructor (args) {
        let self = this;
        self._state.args = args;
        self._init();
    };

    _elems = {
        $_    : $(),
        $form : $()
    };

    _state = {
        args : {}
    };

    _bindElements = () => {
        let self = this;

        self._elems.$_ = self._state.args.root;
        self._elems.$form = self._state.args.form;
    };

    _render = () => {
        let self = this;

        if (!self._state.args.items || !Object.keys(self._state.args.items).length) return;

        const entity = self._state.args.entity;

        Object.keys(self._state.args.items).forEach(function(id) {
            const item = self._state.args.items[id];

            if (self._elems.$_.attr('id') !== 'dropzone-' + id) return;
            let dropzone = new DropzoneLib('#' + self._elems.$_.attr('id'), item.options);
            if (window.dropzoneProcessing == undefined) {
                window.dropzoneProcessing = 0;
            }

            dropzone.on('processing', function (file) {
                window.dropzoneProcessing === 0 && self._elems.$form.find('.button, .confirm').attr('disabled', 'disabled');
                ++window.dropzoneProcessing;
            });

            dropzone.on('error', function (file) {
                ++window.dropzoneProcessing;
            });

            dropzone.on('complete', function (file) {
                --window.dropzoneProcessing;
                window.dropzoneProcessing === 0 && self._elems.$form.find('.button, .confirm').removeAttr('disabled');
                if (file && file.status === 'canceled') return;

                const response = file && file.xhr ? JSON.parse(file.xhr.response) : null;

                if (!response || !response.error) {
                    item.complete(file, response, dropzone, entity);
                    return;
                }

                let error = '';
                for (let field in response.error.fields){
                    error += response.error.fields[field] + '; ';
                }
                error = error.substr(0, error.length - 2)

                new Modal({
                    title: 		'Ошибка',
                    content: 	Utils.renderProfileTemplate('error', {
                        error : {
                            text : error
                        },
                        buttons : {
                            one : {
                                class : 'confirm button--edit',
                                title : 'Ок'
                            },
                            two : null
                        }
                    })
                });
            });

            item.done && item.done(dropzone, entity);

            if(!item.sortable) return;
            self._elems.$_.sortable({
                items     : '.dz-preview.dz-sortable',
                cursor    : 'move',
                opacity   : 0.5,
                distance  : 20,
                tolerance :	'pointer',
                helper    : 'clone',
                start     : function() {
                    self._elems.$_.find('.dz-preview').removeClass('dz-success');
                }
            });
        });
    };

    _init = () => {
        let self = this;

        if (!self._state.args.root || !self._state.args.root.length) return;

        self._bindElements();
        self._render();
    }
}

export default Dropzone;