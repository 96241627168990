import niceScroll from 'jquery.nicescroll/dist/jquery.nicescroll.min.js';

import Vars from './../../vars';

import Request from './../../components/request';
import Utils from './../../components/utils';

import _NAV from './../Const/_NAV';
import _PARAMS from './../Const/_PARAMS';

class Referral {
    constructor(args) {
        let self = this;

        self._setArgs(args);
        self._init();
    }

    _const = {
        entities : 'referrals',
        entity   : 'referral'
    };

    _elems = {
        $content  : $(),
        $cash     : $(),
        $tabs     : $(),
        $referral : $()
    };

    _args = {
        page : '',
        type : '',
        id   : 0
    };

    _state = {
        items : {},
        item  : {}
    };

    _setArgs = (args) => {
        let self = this;

        self._args.page = args.page ? args.page : '';
        self._args.type = args.type ? args.type : '';
        self._args.id   = args.id ? args.id : 0;
    };

    _bindElements = () => {
        let self = this;

        self._elems.$content = !self._elems.$content.length ? $('.content') : self._elems.$content;
        if(!self._elems.$content.length) return;

        self._elems.$cash = $('.cash');

        self._elems.$tabs = self._elems.$content.find('.content-tabs');
        self._elems.$referral = self._elems.$content.find('[data-entity="referral"]');

        self._bindUI();
    };

    _bindUI = () => {
        let self = this;

        //self._elems.$content.find('[data-nicescroll]').niceScroll();

        self._bindTabs();
        self._bindReferral();
    };

    _bindTabs = () => {
        let self = this;
        if (!self._elems.$tabs.length) return;

        self._elems.$tabs.find('.content-tabs-heading a').click(function(e){
            e.preventDefault();
            self._elems.$tabs.find('.content-tabs-heading a').removeClass('--active');
            $(this).addClass('--active');

            self._elems.$tabs.find('.content-tabs-content [data-name]').removeClass('--active');
            self._elems.$tabs.find('.content-tabs-content [data-name="' + $(this).data('target') + '"]').addClass('--active');
        });

        self._elems.$content.find('.content-tabs-heading a:first').trigger('click');
    };

    _bindReferral = () => {
        let self = this;
        if (!self._elems.$referral.length) return;

        self._elems.$referral.click(function(e) {
            e.preventDefault();

            window.location.href = '#referral/' + $(this).data('type') + '/' + $(this).data('id')
        });
    };

    _list = () => {
        let self = this;

        Request.send({
            path : '/api/partners/' + self._const.entities + '/',
            done : function(response) {
                loader.decrease();
                Utils.scrollToTop();

                self._state.items = response[self._const.entities];
                self._state.item  = {};

                let nav = Utils.renderTemplate('nav', {
                    nav    : _NAV,
                    active : 'index'
                });
                let heading = Utils.renderTemplate('heading', {
                    title : 'Привлеченные партнеры и застройщики'
                });
                let tabs = Utils.renderTemplate('tabs', {
                    tabs : {
                        partners   : {
                            title   : 'Партнеры',
                            count   : self._state.items.partners.length,
                            content : Utils.renderTemplate(self._const.entities + '-list', {
                                [self._const.entities] : self._state.items.partners,
                                type                   : 'partner'
                            })
                        },
                        developers : {
                            title   : 'Застройщики',
                            count   : self._state.items.developers.length,
                            content : Utils.renderTemplate(self._const.entities + '-list', {
                                [self._const.entities] : self._state.items.developers,
                                type                   : 'developer'
                            })
                        }
                    }
                });

                self._elems.$content.html(Utils.renderTemplate(self._const.entities, {
                    nav     : nav,
                    heading : heading,
                    tabs    : tabs
                }));

                self._elems.$cash.trigger('update');
                self._bindElements();
            },
            fail : function() {
                //window.location.reload();
            }
        });
    };

    _item = () => {
        let self = this;

        Request.send({
            path : '/api/partners/' + self._args.type + '/' + self._args.id + '/',
            done : function(response) {
                loader.decrease();
                Utils.scrollToTop();

                self._state.item  = response[self._const.entity];
                self._state.items = {};

                let nav = Utils.renderTemplate('nav', {
                    nav    : _NAV,
                    active : 'index'
                });
                let heading = Utils.renderTemplate('heading', {
                    title : self._state.item.name ? self._state.item.name : self._state.item.email,
                    back  : {
                        title : 'Вернуться к списку',
                        url   : '#'
                    }
                });

                self._elems.$content.html(Utils.renderTemplate(self._const.entity, {
                    nav      : nav,
                    heading  : heading,
                    type     : self._args.type,
                    revenues : response.revenues,
                    _PARAMS  : _PARAMS
                }));

                self._elems.$cash.trigger('update');
                self._bindElements();
            },
            fail : function() {
                //window.location.reload();
            }
        });
    };

    _init = () => {
        let self = this;

        if (self._args.page == Vars.page.list) {
            self._list();
        }
        else if (self._args.page == Vars.page.item) {
            self._item();
        }

        self._bindElements();
    }
}

export default Referral;