import Vars from './../../vars';

import Utils from './../../components/utils';

import _NAV from './../Const/_NAV';

class Marketing {
    constructor(args) {
        let self = this;

        self._setArgs(args);
        self._init();
    }

    _elems = {
        $content : $(),
        $cash     : $()
    };

    _args = {
        page : ''
    };

    _state = {
        items : {}
    };

    _setArgs = (args) => {
        let self = this;

        self._args.page = args.page;
    };

    _bindElements = () => {
        let self = this;

        self._elems.$content = !self._elems.$content.length ? $('.content') : self._elems.$content;
        if(!self._elems.$content.length) return;

        self._elems.$cash = $('.cash');
    };

    _index = () => {
        let self = this;

        self._elems.$cash.trigger('update');

        let nav = Utils.renderTemplate('nav', {
            nav    : _NAV,
            active : 'marketing'
        });
        let heading = Utils.renderTemplate('heading', {
            title : 'Реферальные ссылки'
        });

        self._elems.$content.html(Utils.renderTemplate('marketing', {
            nav     : nav,
            heading : heading
        }));
    };

    _init = () => {
        let self = this;

        self._bindElements();

        if (self._args.page == Vars.page.index) {
            self._index();
        }
    }
}

export default Marketing;