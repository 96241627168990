import $ from 'jquery';

window.jQuery = $;
window.$ = $;

import pModal from './../profile/classes/Modal';
import sModal from './../site/classes/Modal';

let modals = [];
modals.$last = null;

class Modal {
    constructor (args) {
        let self = this;

        self._setArgs(args);
        self._init();

        return self._elems.$_;
    };

    _elems = {
        $_        : $(),
        $save     : $(),
        $cancel   : $()
    };

    _state = {
        id    : 0
    };

    _args = {
        widget    : {},
        element   : '',
        classes   : null,
        closing   : false,
        isProfile : true,
        trigger   : $()
    };

    _setArgs = (args) => {
        let self = this;

        self._args.widget    = args.widget ? args.widget : {};
        self._args.element   = args.element ? args.element : null;
        self._args.classes   = args.classes ? args.classes : null;
        self._args.closing   = args.closing ? args.closing : false;
        self._args.isProfile = args.isProfile != undefined ? args.isProfile : true;

        self._args.args      = args;
    };

    _render = () => {
        let self = this;

        self._state.id = modals.length;

        if (self._args.element) {
            self._elems.$_ = $(self._args.element);
            if (!self._elems.$_.length) return;

            if (self._args.isProfile) {
                self._elems.$_ = new pModal({
                    root : self._elems.$_,
                    id   : self._state.id,
                    args : self._args
                });
            }
            else {
                self._elems.$_ = new sModal(Object.assign(self._args.args, {
                    root : self._elems.$_,
                    id   : self._state.id
                }));
            }
        } else {
            if (self._args.isProfile) {
                self._elems.$_ = new pModal({
                    id   : self._state.id,
                    args : self._args
                });
            }
            else {
                self._elems.$_ = new sModal(Object.assign(self._args.args, {
                    id: self._state.id
                }));
            }
        }

        if (Object.keys(self._args.widget).length) {
            self._elems.$_.addClass(self._args.widget.color.name);
            self._elems.$_.addClass('widget-' + self._args.widget.widget_id);
            self._elems.$_.data('widget-id', self._args.widget.widget_id);
        }

        if (self._args.classes) {
            self._elems.$_.addClass(self._args.classes);
        }

        modals.push(self._elems.$_);
        modals.$last = self._elems.$_;
    };

    _bindElements = () => {
        let self = this;
        self._elems.$save   = self._elems.$_.find('.button--confirm');
        self._elems.$cancel = self._elems.$_.find('.button--cancel');
    };

    _bindUI = () => {
        let self = this;

        self._elems.$_.on('closed', function(){
            self._close();
            self._args.closing && self._args.closing();
        });
    };

    _close = () => {
        modals.pop();
        modals.$last = modals.length > 0 ? modals[modals.length - 1] : null;
    };

    _init = () => {
        let self = this;

        self._render();
        self._bindElements();
        self._bindUI();
    }
}

export default Modal;