import Modal from './../components/Modal';
import Template from './../components/template';

export default {
    renderProfileTemplate : function(name, parameters) {
        return Template.render('profile-' + name, parameters);
    },
    renderTemplate : function(name, parameters) {
        if (parameters == undefined) {
            parameters = {};
        }
        parameters.priceFormat = function(value) {
            value = Math.round(value * 100) / 100;
            return String(value).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ');
        };
        parameters.phoneFormat = function(value) {
            return value.slice(0, 1) + ' (' + value.slice(1, 4) + ') ' + value.slice(4, 7) + '-' + value.slice(7, 9) + '-' + value.slice(9, 11);
        };
        parameters.SVGfyPolygonCoordinates = function(polygon, height) {
            return polygon.coordinates.map(function(point) {
                return [
                    point[0],
                    height - point[1],
                ].join(',');
            }).join(' ');
        };

        return Template.render(name, parameters);
    },
    scrollToTop : function() {
        $(window).scrollTop(0);
    },
    selectizeClearOptions: function(selectize){
        selectize.clear();
        selectize.clearOptions();
        selectize.renderCache['option'] = {};
        selectize.renderCache['item'] = {};
    },
    getDistance : function(location0, location1) {
        const toRad = function(value) { return value * Math.PI / 180; };
        const lon1 = location0[0];
        let lat1 = location0[1];
        const lon2 = location1[0];
        let lat2 = location1[1];
        const R = 6371; // km
        const dLat = toRad(lat2 - lat1);
        const dLon = toRad(lon2 - lon1);
        lat1 = toRad(lat1);
        lat2 = toRad(lat2);

        const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        const d = R * c;
        return d;
    },
    notFound : function(args) {
        let self = this;
        const title = 'Ошибка 404';
        const redirect = args.redirect;

        new Modal({
            title   : title,
            content : self.renderProfileTemplate('info', {
                message  : args.message,
                buttons              : {
                    one : {
                        class : 'confirm button--edit',
                        title : 'Хорошо'
                    },
                    two : null
                }
            }),
            confirm : function(args) {
                args.modal.trigger('close');
                window.location.href = redirect;
            }
        });
    }
};