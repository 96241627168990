import $ from 'jquery';

export default {
    _elems : {
        $_ : $('.ifacade-loader')
    },
    _state : {
        count : 0
    },
    increase : function() {
        let self = this;
        ++self._state.count;
        self.update();
    },
    decrease : function() {
        let self = this;
        self._state.count = self._state.count === 0 ? 0 : self._state.count - 1;
        self.update();
    },
    update : function() {
        let self = this;

        if (self._state.count === 0) {
            self._elems.$_.hide();
        } else {
            self._elems.$_.show();
        }
    },
    init : function(args){
        let self = this;

        if (args && args.$root && args.$root.length) {
            self._elems.$_ = args.$root;
        }
        else if (!$('.ifacade-loader').length) {
            $(document).ready(function(){
                let $wrapper = $('<div />')
                    .addClass('ifacade-loader__wrapper');

                for (let i = 0; i < 9; i++) {
                    $wrapper
                        .append(
                            $('<div/>')
                                .addClass('ifacade-loader__box')
                        );
                }

                self._elems.$_ = $('<div/>')
                    .addClass('ifacade-loader--widget')
                    .append(
                        $('<div/>')
                            .addClass('ifacade-loader')
                            .append($wrapper)
                    );

                $('body').append(self._elems.$_);
            });
        }

        return self;
    }
};