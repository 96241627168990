require ('jquery-mask-plugin');

import Address from "./Address";
import Dropzone from './Dropzone';

import Utils from '../../components/utils';
import M from '../../components/Modal';

class Modal {
    constructor(args) {
        let self = this;

        self._setArgs(args);
        self._init();

        return self._elems.$_;
    };

    _elems = {
        $_        : $(),
        $form     : $(),
        $confirm  : $(),
        $cancel   : $(),
        $close    : $(),
        $dropzone : $(),
        $address  : $(),
        $phone    : $(),
        $email    : $(),
        $metrika  : $(),
        $google   : $()
    };

    _state = {
        args : {}
    };

    _args = {
        id    : 0,
        modal : {}
    };

    _setArgs = (args) => {
        let self = this;

        self._args.id    = args.id;
        self._args.modal = args.args.args;
    };

    _bindElements = () => {
        let self = this;

        if (!self._elems.$_.length && self._args.modal && self._args.modal.root) {
            self._elems.$_ = self._args.modal.root;
        }
        if (!self._elems.$_.length) return;

        self._elems.$form     = self._elems.$_.find('form');
        self._elems.$confirm  = self._elems.$_.find('.confirm:not(.button--cancel), .button:not(.button--cancel)');
        self._elems.$cancel   = self._elems.$_.find('.button--cancel');
        self._elems.$close    = self._elems.$_.find('.js-modal-close');
        self._elems.$address  = self._elems.$_.find('.input.is-address');
        self._elems.$dropzone = self._elems.$_.find('.dropzone');
        self._elems.$phone    = self._elems.$_.find('input[type="tel"]');
        self._elems.$email    = self._elems.$_.find('input[type="email"]');
        self._elems.$metrika  = self._elems.$_.find('input[name="site[yandex]"]');
        self._elems.$google   = self._elems.$_.find('input[name="site[google]"]');
    };

    _bindClose = () => {
        let self = this;

        self._elems.$_.on('close', function(){
            self._close();
        });

        self._elems.$_.click(function(e) {
            if ($(e.target).closest('.js-modal-close').length > 0) {
                e.preventDefault();
                if (self._elems.$cancel.length) {
                    self._elems.$cancel.trigger('click');
                } else {
                    if (self._args.modal.closingConfirmation) {
                        if (self._args.closing) {
                            self._args.closing({
                                modal: self._elems.$_
                            });
                        } else {
                            new M ({
                                title   : 'Подтверждение закрытия окна',
                                content : Utils.renderTemplate('closing-modal-confirmation', {
                                    'text' : 'Вы действительно хотите закрыть это окно без сохранения внесенных изменений?'
                                }),
                                confirm : function(args) {
                                    args.modal && args.modal.trigger('close');
                                    self._elems.$_.trigger('close');
                                }
                            });
                        }
                        return;
                    }
                    self._elems.$_.trigger('close');
                }
            }

            if ($(e.target).closest('.ifacade__modal__container').length === 0) {
                e.preventDefault();

                self._elems.$close.trigger('click');
            }
        });
    };

    _bindConfirm = () => {
        let self = this;

        if (!self._elems.$confirm.length) return;

        self._elems.$form.submit(function(e){
            e.preventDefault();

            if (self._args.modal.confirm) {
                self._args.modal.confirm({
                    modal : self._elems.$_
                });
            } else {
                self._elems.$_.trigger('close');
            }
        });

        self._elems.$confirm.click(function(e){
            e.preventDefault();

            if (self._elems.$form.length) {
                self._elems.$form.trigger('submit');
            } else {
                if (self._args.modal.confirm) {
                    self._args.modal.confirm({
                        modal : self._elems.$_
                    });
                } else {
                    self._elems.$_.trigger('close');
                }
            }

        });
    };

    _bindCancel= () => {
        let self = this;

        if (!self._elems.$cancel.length) return;

        self._elems.$cancel.click(function(e){
            e.preventDefault();

            if (self._args.modal.closingConfirmation) {
                if (self._args.closing) {
                    self._args.closing({
                        modal: self._elems.$_
                    });
                } else {
                    new M ({
                        title   : 'Подтверждение закрытия окна',
                        content : Utils.renderTemplate('closing-modal-confirmation', {
                            'text' : 'Вы действительно хотите закрыть это окно без сохранения внесенных изменений?'
                        }),
                        confirm : function(args) {
                            args.modal && args.modal.trigger('close');
                            self._elems.$_.trigger('close');
                        }
                    });
                }
                return;
            } else if (self._args.closing) {
                self._args.closing({
                    modal: self._elems.$_
                });
                return;
            }

            self._close();
        });

    };

    _bindClick = () => {
        let self = this;

        self._elems.$_.click(function(e) {
            let $input_element = $(e.target).closest('.input__element');
            if (!$input_element.length) {
                if ($(e.target).closest('.input-tag__cross').length > 0) {
                    e.preventDefault();
                    let $input_tag = $(e.target).closest('.input-tag');
                    let $input = $input_tag.closest('.input');
                    const input_tag_index = $input.children('.input-tag').toArray().indexOf($input_tag[0]);
                    $input_tag.remove();
                    let $input_tag_input = $input.children('input[type="hidden"]').eq(input_tag_index);
                    $input.find('.input__element[data-id="' + $input_tag_input.val() + '"]').show();
                    $input_tag_input.remove();
                    return;
                }

                let $input = $(e.target).closest('.input.taggable');

                if ($input.length > 0) {
                    $input.find('input[type="text"]').focus();
                    return;
                }

                $input = $('.input.taggable');

                if (!$input.length) return;

                $input.removeClass('showing-results focused');
                return;
            }

            let $input = $input_element.closest('.input.taggable');

            if ($input.length) {
                e.preventDefault();
                let $input_text_input = $input.find('input[type="text"]');
                let $input_tag = $('<div />');
                $input_tag.addClass('input-tag');
                let name = $input_element.text();
                let description = null;

                if ($input.hasClass('is-subway')) {
                    let location = $input.attr('data-location');

                    location = location ? location.split(/,/).map(function (coordinate) {
                        return parseFloat(coordinate);
                    }) : null;

                    let input_element_location = $input_element.attr('data-location');

                    input_element_location = input_element_location ? input_element_location.split(/,/).map(function (coordinate) {
                        return parseFloat(coordinate);
                    }) : null;

                    if (location && input_element_location) {
                        var distance = get_distance(location, input_element_location);
                        description = '(' + (distance < 1.0 ? Math.round(distance * 1000) : Math.round(distance * 100) / 100) + ' ' + (distance < 1.0 ? 'м' : 'км') + '.)';
                    }
                }
                else if ($input.hasClass('is-bank')) {
                    name = $input_element.text().split(/\(/)[0].trim(),
                        description = '(' + $input_element.text().split(/\(/)[1].split(/\)/)[0].trim() + ')';
                }

                $input_tag.html(Utils.renderTemplate('input-tag', {
                    name: name || '?',
                    description: description || '',
                }));

                $input_tag.insertBefore($input_text_input);

                let $input_hidden_input = $('<input />');
                $input_hidden_input.attr('type', 'hidden');
                $input_hidden_input.attr('name', $input.attr('data-name') + '[]');
                $input_hidden_input.val($input_element.attr('data-id'));
                $input_hidden_input.insertBefore($input_text_input);
                $input_text_input.val('');
                $input.find('.input__elements').html('');
                $input_element.hide();
                $input.removeClass('showing-results focused');
                $input.trigger('change');
            }
            else {
                let $input_address_element = $(e.target).closest('.input__address-element');
                $input = $();

                if (!$input_address_element.length) {
                    if ($(e.target).closest('.input.is-address').length > 0) return;

                    $input = $('.input.is-address');

                    if (!$input.length) return;

                    $input.removeClass('showing-results focused');
                    return;
                }

                $input = $input_address_element.closest('.input.is-address');

                if (!$input.length) return;

                e.preventDefault();
                $input.attr('data-newparentid', $input_address_element.attr('data-parentid'));
                $input.find('input[type="text"]').val($input_address_element.text());
                $input.find('input[type="hidden"]')
                    .val($input_address_element.attr('data-id'))
                    .trigger('change');

                $input.removeClass('showing-results').addClass('changed');
                $input.trigger('change');
            }
        });
    };

    _bindFocus = () => {
        let self = this;

        let update = function(e) {
            let $input = $(e.target).closest('.input.taggable');

            if (!$input.length) return;

            if ($input.attr('data-elements')) {
                let elements = JSON.parse($input.attr('data-elements'));

                const tagIds = $input.find('input[type="hidden"]').toArray().map(function(element) {
                    return $(element).val();
                });

                elements = elements.filter(function(element) {
                    return tagIds.indexOf(element.id.toString()) < 0;
                });

                if (elements.length == 0) {
                    $input.removeClass('showing-results');
                    return;
                }

                let $elements = $input.find('.input__elements');
                $elements.html('');

                elements.forEach(function(element) {
                    let $a = $('<a />');
                    $a.addClass('input__element');
                    $a.attr('href', '#');
                    $a.text(element.name);
                    $a.attr('data-id', element.id);
                    $a.appendTo($elements);
                });

                $input.hasClass('focused') && $input.addClass('showing-results');
                return;
            }

            if ($input.attr('data-url')) {
                if ($input.attr('data-last-value') != '' && $input.attr('data-last-value') == $(e.target).val()) return;

                $input.attr('data-last-value', $(e.target).val());

                if ($input.attr('data-last-timeout-id')) {
                    clearTimeout(parseInt($input.attr('data-last-timeout-id')));
                }

                let timeoutId = setTimeout(function() {
                    $input.attr('data-last-timeout-id', '');
                    $input.addClass('loading');

                    return $.ajax({
                        url: $input.attr('data-url'),
                        data: {
                            input: $input.find('input[type="text"]').val(),
                        },
                        success: function(response) {
                            $input.removeClass('loading');

                            const tagIds = $input.find('input[type="hidden"]').toArray().map(function(element) {
                                return $(element).val();
                            });

                            let filtered_elements = response.elements.filter(function(element) {
                                return tagIds.indexOf(element.id.toString()) < 0;
                            });

                            if (filtered_elements.length == 0) {
                                $input.removeClass('showing-results');
                                return;
                            }

                            let $elements = $input.find('.input__elements');
                            $elements.html('');

                            response.elements.forEach(function(element) {
                                let $a = $('<a />');
                                $a.addClass('input__element');
                                $a.attr('href', '#');

                                if ($input.hasClass('is-bank')) {
                                    $a.text(element.title + ' ' + element.name + ' (№' + element.number + ')');
                                }
                                else {
                                    $a.text(element.name);
                                }

                                $a.attr('data-id', element.id);

                                if ($input.hasClass('is-subway')) {
                                    $a.attr('data-location', element.location.join(','));
                                }

                                $a.appendTo($elements);

                                if (tagIds.indexOf(element.id.toString()) > -1) {
                                    $a.hide();
                                }
                            });

                            $input.hasClass('focused') && $input.addClass('showing-results');
                        },
                    });
                }, 500);

                $input.attr('data-last-timeout-id', timeoutId);
            }
        };

        self._elems.$_.on('focus', 'input[type="text"]', function(e) {
            e.preventDefault();

            let $input = $(e.target).closest('.input.taggable');

            if (!$input.length) return;

            $input.addClass('focused');

            if ($input.find('.input__elements').children().length == 0) {
                update(e);
                return;
            }

            $input.addClass('showing-results');
        });

        self._elems.$_.on('keyup', 'input[type="text"]', function(e) {
            update(e);
        });
    };

    _bindCheckbox = () => {
        let self = this;

        self._elems.$_.on('click', '.form-field .checkbox', function(e) {
            e.preventDefault();

            let $inputs = $(this).children('input');

            if ($(e.target).is('.checkbox__enable')) {
                $(this).removeClass('disabled');
                $inputs.eq(0).prop('disabled', $(this).hasClass('checked'));
                $inputs.eq(1).prop('disabled', !$(this).hasClass('checked'));
                $(this).trigger('enable').trigger('change');
                return;
            }

            if ($(e.target).is('.checkbox__disable')) {
                $(this).addClass('disabled');
                $inputs.prop('disabled', true);
                $(this).trigger('disable').trigger('change');
                return;
            }

            if ($(this).hasClass('disabled')) return;

            if ($(this).hasClass('checked')) {
                $inputs.eq(0).prop('disabled', false);
                $inputs.eq(1).prop('disabled', true);
                $(this).removeClass('checked');
                $(this).trigger('change');
                $(this).trigger('uncheck');
            }
            else {
                $inputs.eq(0).prop('disabled', true);
                $inputs.eq(1).prop('disabled', false);
                $(this).addClass('checked');
                $(this).trigger('change');
                $(this).trigger('check');
            }
        });

        self._elems.$_.on('click', '.checklist-item', function(e) {
            e.stopPropagation();
            e.preventDefault();

            if (!$(this).hasClass('disabled')) {
                if ($(this).hasClass('checked')) {
                    $(this).removeClass('checked');
                    $(this).children('.checklist-item__input').prop('disabled', true);
                }
                else {
                    $(this).addClass('checked');
                    $(this).children('.checklist-item__input').prop('disabled', false);
                }

                $(this).trigger('update');
            }
        });

        self._elems.$_.on('update', '.checklist-item', function(e, childUpdate) {
            e.stopPropagation();
            e.preventDefault();

            let $checklist = $(this).parents('.checklist').eq(0);
            let $parentChecklistItem = $checklist.parents('.checklist-item').eq(0);
            let $childCheklist = $(this).find('.checklist').eq(0);

            if ($parentChecklistItem.length) {
                let $checklistItems = $checklist.children('.checklist-item:not(.disabled)');

                const thereIsOneCheckedItem = $checklistItems.toArray().some(function (element) {
                    return $(element).hasClass('checked');
                });

                $parentChecklistItem.each(function () {
                    if (thereIsOneCheckedItem) {
                        $(this).addClass('checked');
                        $(this).children('.checklist-item__input').prop('disabled', false);
                        $(this).trigger('update', true);
                    }
                    else {
                        $(this).removeClass('checked');
                        $(this).children('.checklist-item__input').prop('disabled', true);
                        $(this).trigger('update', true);
                    }
                });
            }
            else {
                if ($(this).parent().hasClass('checklist-feed-images')) {
                    if ($(this).hasClass('checked')) {
                        if (!$(this).find('.checklist-item__checklist .checklist-item.checked').length || $(this).find('.checklist-item__checklist .checklist-item.checked').length > 1) {
                            $(this).find('.checklist-item__checklist .checklist-item').each(function () {
                                $(this).removeClass('checked');
                                $(this).children('.checklist-item__input').prop('disabled', true);

                                if ($(this).index() == 0) {
                                    $(this).addClass('checked');
                                    $(this).children('.checklist-item__input').prop('disabled', false);
                                }
                            });
                        }
                    }
                    else {
                        $(this).find('.checklist-item__checklist .checklist-item').each(function () {
                            $(this).removeClass('checked');
                            $(this).children('.checklist-item__input').prop('disabled', true);
                        });
                    }
                }
                else if ($(this).index() == 0 && !$(this).parent().hasClass('without-all')) {
                    $(this).children('.checklist-item__input').prop('disabled', false);
                    $(this).children('.checklist-item__input').val(1);
                    $checklist.children('.checklist-item').slice(1).each(function () {
                        $(this).removeClass('checked');
                        $(this).children('.checklist-item__input').prop('disabled', true);
                        let $childCheklist = $(this).find('.checklist').eq(0);

                        if (!$childCheklist.length) return;
                        $childCheklist.children('.checklist-item').each(function () {
                            $(this).removeClass('checked');
                            $(this).children('.checklist-item__input').prop('disabled', true);
                        });
                    });

                    $(this).addClass('checked');
                }
                else {
                    let $checklistItems = $checklist.children('.checklist-item:not(.disabled)');

                    const thereIsOneCheckedItem = $checklistItems.slice(1).toArray().some(function (element) {
                        return $(element).hasClass('checked');
                    });

                    if (!$(this).parent().hasClass('without-all')) {
                        $checklistItems.eq(0).each(function () {
                            if (thereIsOneCheckedItem) {
                                $(this).removeClass('checked');
                                $(this).children('.checklist-item__input').val(0);
                            }
                            else {
                                $(this).addClass('checked');
                                $(this).children('.checklist-item__input').val(1).removeAttr('disabled');
                            }
                        });
                    }

                    if (!childUpdate && $childCheklist.length > 0) {
                        if ($(this).hasClass('checked')) {
                            $childCheklist.children('.checklist-item:not(.disabled)').each(function () {
                                $(this).addClass('checked');
                                $(this).children('.checklist-item__input').prop('disabled', false);
                            });
                        }
                        else {
                            $childCheklist.children('.checklist-item:not(.disabled)').each(function () {
                                $(this).removeClass('checked');
                                $(this).children('.checklist-item__input').prop('disabled', true);
                            });
                        }
                    }
                }
            }
        });
    };

    _bindNumber = () => {
        let self = this;

        self._elems.$_.on('keyup', 'input[type="number"]', function(e) {
            let $input = $(e.target).closest('.input');
            if (!$input.length) return;

            let value = parseInt($(this).val());
            $(this).val() != value && $(this).val(value);
        });

        self._elems.$_.on('focus', 'input[type=number]', function (e) {
            $(this).on('wheel.disableScroll', function (e) {
                e.preventDefault();
            })
        });

        self._elems.$_.on('blur', 'input[type=number]', function (e) {
            $(this).off('wheel.disableScroll');
        })
    };

    _bindFloat = () => {
        let self = this;

        self._elems.$_.on('keyup', 'input[type="text"]', function(e) {
            let $input = $(e.target).closest('.input.float');

            if (!$input.length) return;

            let value = $(this).val();
            value = value.replace(/,/g, '.').replace(/[^\d.]*/g, '')
                .replace(/([.])[.]+/g, '$1')
                .replace(/^[^\d]*(\d+([.]\d{0,5})?).*$/g, '$1');
            $(this).val() != value && $(this).val(value);
        });

        self._elems.$_.on('change', 'input[type="text"]', function(e) {
            let $input = $(e.target).closest('.input.float');

            if (!$input.length) return;

            let value = $(this).val();
            value = value.replace(/,/g, '.').replace(/[^\d.]*/g, '')
                .replace(/([.])[.]+/g, '$1')
                .replace(/^[^\d]*(\d+([.]\d{0,5})?).*$/g, '$1');
            $(this).val() != value && $(this).val(value);
        });
    };

    _bindPrice = () => {
        let self = this;

        self._elems.$_.on('keyup', 'input[type="text"]', function(e) {
            let $input = $(e.target).closest('.input.price');

            if (!$input.length) return;

            let value = $(this).val();
            value = value.replace(/,/g, '.')
                .replace(/[^\d.]*/g, '')
                .replace(/([.])[.]+/g, '$1')
                .replace(/\s+/g, '')
                .replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ');
            $(this).val() != value && $(this).val(value);
        });
    };

    _bindEnable = () => {
        let self = this;

        self._elems.$_.on('click', '.input__enable, .input__disable', function(e) {
            e.preventDefault();
            let $input = $(this).parents('.input');

            if ($(this).is('.input__enable')) {
                $input.removeClass('disabled');
                $input.find('input').prop('disabled', false).focus();
                return;
            }

            if ($(this).is('.input__disable')) {
                $input.addClass('disabled');
                $input.find('input').prop('disabled', true);
                return;
            }
        });

        self._elems.$_.on('click', '.select__enable, .select__disable', function(e) {
            e.preventDefault();
            let $input = $(this).parents('.select');

            if ($(this).is('.select__enable')) {
                $input.removeClass('disabled');
                $input.find('select').prop('disabled', false).focus().trigger('change');
                return;
            }

            if ($(this).is('.select__disable')) {
                $input.addClass('disabled');
                $input.find('select').prop('disabled', true).trigger('change');
                return;
            }
        });

        self._elems.$_.on('click', '.textarea__enable, .textarea__disable', function(e) {
            e.preventDefault();
            let $input = $(this).parents('.textarea');

            if ($(this).is('.textarea__enable')) {
                $input.removeClass('disabled');
                $input.find('textarea').prop('disabled', false).focus();
                return;
            }

            if ($(this).is('.textarea__disable')) {
                $input.addClass('disabled');
                $input.find('textarea').prop('disabled', true);
                return;
            }
        });
    };

    _bindSlider = () => {
        let self = this;

        self._elems.$_.on('click', '.form-slider__title', function(e) {
            e.preventDefault();

            let $slider = $(this).parent();
            $slider.toggleClass('open');
        });
    };

    _bindPhones = () => {
        let self = this;

        if (!self._elems.$phone.length) return;
        self._elems.$phone.mask('8 (999) 999-99-99');

        let $extra = self._elems.$_.find('[data-name="extra_phone"]');
        if (!$extra.length) return;

        let count = $extra.find('input[type="tel"][value!=""]').length;

        $extra.on('update', function(){
            if (count === 0) {
                $extra.find('.form-group__link.remove').hide();
            } else {
                $extra.find('.form-group__link.remove').show();
            }
            if (count >= 5) {
                $extra.find('.form-group__link.add').hide();
            } else {
                $extra.find('.form-group__link.add').show();
            }

            $extra.find('.form-group__section').slice(0, count).show();

            $extra.find('.form-group__section').slice(count).each(function() {
                $(this).find('input[type="text"]').val('');
            }).hide();
        });

        $extra.find('.form-group__link.add').click(function(e) {
            e.preventDefault();
            ++count;
            $extra.trigger('update');
        });

        $extra.find('.input__remove').click(function(e) {
            e.preventDefault();
            let $formGroupSection = $(this).closest('.form-group__section');
            if (!$formGroupSection.length) return;

            let extraPhones = [];
            $extra.find('[data-name^=extra_phone]').each(function(){
                const $input = $(this).find('input[type="tel"]');
                extraPhones.push($input.val());
            });

            extraPhones.splice($formGroupSection.index(), 1);

            extraPhones.forEach(function(extraPhone, index) {
                $extra.find('[data-name^="extra_phone' + index + '"] input').val(extraPhone);
            });

            $extra.find('.form-group__section').each(function(){
                let $section = $(this);
                if ($section.index() >= $formGroupSection.index()) {
                    let $next = $section.next();
                    if (!$next.length) return;

                    $section.find('.checkbox').each(function(){
                        let $checkbox = $(this);
                        let $copy = $next.find('.checkbox').eq($checkbox.index());
                        if ($copy.attr('class') != $checkbox.attr('class'))
                            $checkbox.trigger('click');
                    });
                }
            });

            --count;
            $extra.trigger('update');
        });

        $extra.trigger('update');
    };

    _bindEmails = () => {
        let self = this;

        if (!self._elems.$email.length) return;

        let $extra = self._elems.$_.find('[data-name="extra_email"]');
        if (!$extra.length) return;

        let count = $extra.find('input[type="email"][value!=""]').length;

        $extra.on('update', function(){
            if (count === 0) {
                $extra.find('.form-group__link.remove').hide();
            } else {
                $extra.find('.form-group__link.remove').show();
            }
            if (count >= 15) {
                $extra.find('.form-group__link.add').hide();
            } else {
                $extra.find('.form-group__link.add').show();
            }

            $extra.find('.form-group__section').slice(0, count).show();

            $extra.find('.form-group__section').slice(count).each(function() {
                $(this).find('input[type="text"]').val('');
            }).hide();
        });

        $extra.find('.form-group__link.add').click(function(e) {
            e.preventDefault();
            ++count;
            $extra.trigger('update');
        });

        $extra.find('.input__remove').click(function(e) {
            e.preventDefault();
            let $formGroupSection = $(this).closest('.form-group__section');
            if (!$formGroupSection.length) return;

            let extraEmails = [];
            $extra.find('[data-name^=extra_email]').each(function(){
                const $input = $(this).find('input');
                extraEmails.push($input.val());
            });

            extraEmails.splice($formGroupSection.index(), 1);

            extraEmails.forEach(function(extraPhone, index) {
                $extra.find('[data-name=extra_email' + index + '] input').val(extraPhone);
            });

            $extra.find('.form-group__section').each(function(){
                let $section = $(this);
                if ($section.index() >= $formGroupSection.index()){
                    if ($section.next()){
                        let $next = $section.next().children('[data-name="has_fax"]');
                        $section.find('[data-name="has_fax"] .checkbox').each(function(){
                            let $item = $(this);
                            let $copy = $next.find('.checkbox').eq(item.index());
                            $item.attr('class', $copy.attr('class'));

                            $item.children('input[type="hidden"]').each(function(){
                                let $input = $(this);
                                let $copyInput = $copy.children('input[type="hidden"]').eq($input.index() - 1);
                                $input.removeAttr('disabled');
                                if ($copyInput.attr('disabled') === 'disabled'){
                                    $input.attr('disabled', 'disabled');
                                }
                            })
                        });
                    }
                }
            });

            --count;
            $extra.trigger('update');
        });

        $extra.trigger('update');
    };

    _bindValidation = () => {
        let self = this;
        
        let checkValid = function($field, value, rules){
            let preMatch = '';
            let match    = '';
            let errors   = 'Некорректное значение.';
            if (rules.indexOf('nullable') != -1 && value == '') return;

            if (rules.indexOf('email') != -1){
                match = '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
                errors += ' Укажите правильно email.';
            }

            if (rules.indexOf('domain') != -1){
                match = '^((http|https):\\/\\/)?([a-zA-Z0-9_][-_a-zA-Z0-9]{0,62}\\.)+([a-zA-Z0-9]{1,10})$';
                errors += ' Укажите правильно домен.';
            }

            if (rules.indexOf('yandex.metrika') != -1){
                preMatch = '0-9';
                errors += ' Укажите корректное значение счётчика.';
            }

            if (rules.indexOf('google.analytics') != -1){
                match = '^([A-Z]{2})(\\-)(\\d+)(\\-)(\\d+)$';
                errors += ' Укажите корректное значение счётчика.';
            }

            if (rules.indexOf('facebook.pixel') != -1){
                preMatch = '0-9';
                errors += ' Укажите корректное значение счётчика.';
            }

            if (rules.indexOf('lang:en') != -1) {
                preMatch += 'a-zA-Z';
                errors += ' Используйте латиницу.';
            }
            if (rules.indexOf('integer') != -1) {
                preMatch += '0-9';
                errors += ' Используйте цифры';
            }
            if (rules.indexOf('hyphen') != -1) {
                preMatch += '-';
            }

            for (let key in rules) {
                if (rules[key].indexOf('max:') != -1) {
                    let max = parseInt(rules[key].substr('max:'.length));
                    if (value.length > max) {
                        $field.addClass('error');
                        $field.children('.form-field__message').text('Максимальная длина значения: ' + max).removeClass('hidden');
                        //self._elems.$form.find('input[type=submit], button[type=submit]').attr('disabled', 'disabled');
                        return;
                    }
                }
            }
            match = match == '' && preMatch != '' ? '^[' + preMatch + ']+$' : match;

            if (value.match(match) == null){
                $field.addClass('error');
                $field.children('.form-field__message').text(errors).removeClass('hidden');
                //self._elems.$form.find('input[type=submit], button[type=submit]').attr('disabled', 'disabled');
            }
        };

        self._elems.$form.find('.form-field[data-validation]').each(function(){
            let $field = $(this);

            const rules = $field.data('validation').split(',');
            $field.find('input, textarea').on('keyup', function(){
                $field.removeClass('error');
                $field.children('.form-field__message').text('').addClass('hidden');
                if (!self._elems.$form.find('.form-field.error').length) {
                    self._elems.$form.find('input[type=submit], button[type=submit]').removeAttr('disabled');
                }

                checkValid($field, $(this).val(), rules);
            });
            $field.find('input, textarea').on('change', function(){
                $field.removeClass('error');
                $field.children('.form-field__message').text('').addClass('hidden');
                if (!self._elems.$form.find('.form-field.error').length) {
                    self._elems.$form.find('input[type=submit], button[type=submit]').removeAttr('disabled');
                }

                checkValid($field, $(this).val(), rules);
            });
        });
    };

    _bindMetrika = () => {
        let self = this;
        if (!self._elems.$metrika.length) return;

        self._elems.$metrika.on('keyup', function(){
            const clear = $(this).val().replace(/\D/g, '');
            $(this).val(clear);
        });
    };

    _bindGoogle = () => {
        let self = this;
        if (!self._elems.$google.length) return;

        self._elems.$google.on('keyup', function(){
            const clear = $(this).val().replace(/[^a-zA-Z0-9-]+/, '');
            $(this).val(clear);
        });
    };

    _bindUI = () => {
        let self = this;

        if (!self._elems.$_.length) return;

        self._bindClose();
        self._bindConfirm();
        self._bindCancel();
        self._bindClick();
        self._bindFocus();
        self._bindCheckbox();
        self._bindNumber();
        self._bindFloat();
        self._bindPrice();
        self._bindEnable();
        self._bindSlider();
        self._bindPhones();
        self._bindEmails();
        self._bindValidation();
        /*self._bindMetrika();
        self._bindGoogle();*/
        self._initAddress();
        self._initDropzone();
    };

    _initAddress = () => {
        let self = this;

        if (!self._elems.$address.length) return;

        new Address({
            root : self._elems.$_
        });
    };

    _initDropzone = () => {
        let self = this;

        if (!self._elems.$dropzone.length) return;
        if (!self._args.modal.dropzone || !self._args.modal.dropzone.items) return;

        self._elems.$dropzone.each(function(){
            new Dropzone({
                root  : $(this),
                form  : self._elems.$_,
                items : self._args.modal.dropzone.items,
                entity : self._args.modal.dropzone.entity
            })
        });
    };

    _render = () => {
        let self = this;

        if (self._elems.$_.length) return;

        const className = self._args.size != '' && self._args.size !== undefined ? ' ' + self._args.size : '';
        self._elems.$_ = $('<div />').addClass('ifacade__modal' + className).html(Utils.renderTemplate('modal')).appendTo('body');
        self._elems.$_.find('.ifacade__modal__content').html(self._args.modal.content || 'Содержимое модального окна не установлено');
        self._elems.$_.find('.ifacade__modal__title').text(self._args.modal.title || 'Заголовок модального окна не установлен');
        if (self._args.modal.modalType === 'flat' && (self._args.modal.widget === undefined || self._args.modal.widget === false)) {
            self._elems.$_.find('.ifacade__modal__title').addClass('with-mark');
        } else {
            self._elems.$_.find('.ifacade__modal__title').removeClass('with-mark');
        }

        self._bindElements();
    };

    _open = () => {
        let self = this;

        self._elems.$_.removeClass('hidden').addClass('opened');
        self._elems.$_.parents('.filter').css({'overflow': 'initial', 'z-index': 1000 + self._args.id});

        self._elems.$_.attr('id', 'ifacade__modal-' + self._args.id).css({
            'z-index': 1000 + self._args.id
        });
    };

    _close = () => {
        let self = this;

        self._elems.$_.trigger('closed');
        self._elems.$_.remove();
    };

    _init = () => {
        let self = this;

        self._bindElements();
        self._render();
        self._open();
        self._bindUI();
    }
}

export default Modal;