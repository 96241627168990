export default {
    _groupBy : function(arr, key){
        let newArr = [],
            types = {},
            i, j, cur;
        for (i = 0, j = arr.length; i < j; i++) {
            cur = arr[i];
            if (!(cur[key] in types)) {
                types[cur[key]] = { type: cur[key], data: [] };
                newArr.push(types[cur[key]]);
            }
            types[cur[key]].data.push(cur);
        }
        return newArr;
    },

    _isMobile : {
        Android : function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry : function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS : function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera : function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows : function() {
            return navigator.userAgent.match(/IEMobile/i);
        }
    },

    _isMobileAny : function() {
        return (this._isMobile.Android() || this._isMobile.BlackBerry() || this._isMobile.iOS() || this._isMobile.Opera() || this._isMobile.Windows());
    },

    _isTouch : function() {
        try {
            document.createEvent("TouchEvent");
            return true;
        } catch (e) {
            return false;
        }
    },

    _smallDesktop : function() {
        return $(window).width() < 600;
    },

    _priceFormat : function(value) {
        value = Math.round(value * 100) / 100;
        return String(value).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ');
    },

    _wordforms : function(n, text_forms) {
        n = Math.abs(n) % 100; var n1 = n % 10;
        if (n > 10 && n < 20) { return text_forms[2]; }
        if (n1 > 1 && n1 < 5) { return text_forms[1]; }
        if (n1 == 1) { return text_forms[0]; }
        return text_forms[2];
    },


    _collectFlats : function(module) {
        const type = module._const && module._const.module ? module._const.module : undefined;

        if (type == 'catalog' || type == 'plan') {
            module._collect.flats.base = [];
        }
        else {
            module._collect.flats = [];
        }

        let favourites = [];
        if (!Object.keys(module._collect.widget).length) {
            if (window.ifacade.favourites && Object.keys(ifacade.favourites).length && ifacade.favourites.list) {
                favourites = window.ifacade.favourites.list.map(function (flat) {
                    return flat.id;
                });
            }
        }

        module._collect.object.flats && module._collect.object.flats.forEach(function (flat) {
            if (flat.group && flat.group.main_flat_id != flat.id) return;

            if (flat.group) {
                let flats = module._collect.object.flats.filter(function(f){
                    return f.group && f.group_id == flat.group_id;
                });
                let floorNumbers = [];
                if (flats.length) {
                    flats.forEach(function(f){
                        module._collect.object.floors.filter(function(floor){
                            return floor.id == f.floor_id;
                        }).forEach(function(floor){
                            if (floorNumbers.indexOf(floor.number) == -1) {
                                floorNumbers[floor.number] = floor.number;
                            }
                        });
                    });
                }
                function sortNumber(a, b) {
                    return a - b;
                }

                floorNumbers.sort(sortNumber)
                if (floorNumbers.length) {
                    flat.floors = '';
                    floorNumbers.forEach(function(number){
                        if (number) {
                            flat.floors += (number == 0 ? 'Цокольный' : (number == 1000 ? 'ЭК' : number)) + ', ';
                        }
                    });
                    flat.floors = flat.floors.substr(0, flat.floors.length - 2);
                }
            }

            if (favourites.length && favourites.indexOf(flat.id) != -1) {
                flat.marked = true;
            }

            let flatImageUrl = '/img/default_flat_image.png';
            if (flat.typical_plan_id != 0) {
                let plans = module._collect.object.typical_plans && module._collect.object.typical_plans.filter(function (plan) {
                    return plan.id == flat.typical_plan_id;
                });

                if (plans && plans.length) {
                    let plan = plans[plans.length - 1];
                    flatImageUrl = '/' + (plan.path_to_middle ? plan.path_to_middle : plan.path_to_original);
                } else {
                    module._collect.object.buildings && module._collect.object.buildings.forEach(function (building) {
                        if (building.plans) {
                            plans = building.plans && building.plans.filter(function (plan) {
                                return plan.id == flat.typical_plan_id;
                            });
                            if (plans && plans.length) {
                                let plan = plans[plans.length - 1];
                                flatImageUrl = '/' + (plan.path_to_middle ? plan.path_to_middle : plan.path_to_original);
                            }
                        }
                    });
                }
            } else {
                if (flat.image) {
                    flatImageUrl = '/' + (flat.image.path_to_middle ? flat.image.path_to_middle : flat.image.path_to_original);
                }
            }

            flat.path_to_image = module._state.urlPrefix + flatImageUrl;

            if (type == 'catalog' || type == 'plan') {
                module._collect.flats.base = module._collect.flats.base.concat(JSON.parse(JSON.stringify(flat)));
            }
            else {
                module._collect.flats = module._collect.flats.concat(JSON.parse(JSON.stringify(flat)));
            }
        });
    },

    _collectPlans: function(module){
        const type = module._const && module._const.module ? module._const.module : undefined;

        if (type == 'plan') {
            module._collect.plans.base = [];
        }
        else {
            module._collect.plans = [];
        }

        let flatImageUrl = '/img/default_flat_image.png';

        module._collect.object.typical_plans && module._collect.object.typical_plans.forEach(function(plan){
            flatImageUrl = '/' + ( plan.path_to_middle ? plan.path_to_middle : plan.path_to_original );
            plan.path_to_image =  module._state.urlPrefix + flatImageUrl;

            if (type == 'plan') {
                module._collect.plans.base = module._collect.plans.base.concat(JSON.parse(JSON.stringify(plan)));
            }
            else {
                module._collect.plans = module._collect.plans.concat(JSON.parse(JSON.stringify(plan)));
            }
        });
        module._collect.object.buildings && module._collect.object.buildings.forEach(function(building){
            building.plans && building.plans.forEach(function(plan){
                flatImageUrl = '/' + ( plan.path_to_middle ? plan.path_to_middle : plan.path_to_original );
                plan.path_to_image =  module._state.urlPrefix + flatImageUrl;

                if (type == 'plan') {
                    module._collect.plans.base = module._collect.plans.base.concat(JSON.parse(JSON.stringify(plan)));
                }
                else {
                    module._collect.plans = module._collect.plans.concat(JSON.parse(JSON.stringify(plan)));
                }
            });
        });
    },

    _hasFlatTypesOnlyApartment : function(flats, floor){
        return flats && flats.map(function(flat) {
            if(floor && flat.floor_id != floor.id)
                return null;

            if (flat.type === 'apartment')
                return flat.count_of_rooms;
            if (flat.type == 'office')
                return null;

            return null;
        }).filter(function(flat_type, flat_type_index, flat_types) {
            if (flat_type === null)
                return false;

            return flat_types.indexOf(flat_type) == flat_type_index;
        }).length;
    },

    _hasFlatTypesApartment : function(flats, floor){
        return flats && flats.map(function(flat) {
            if(floor && flat.floor_id != floor.id)
                return null;

            if (['flat', 'apartment'].indexOf(flat.type) !== -1)
                return flat.count_of_rooms;
            if (flat.type == 'office')
                return null;

            return null;
        }).filter(function(flat_type, flat_type_index, flat_types) {
            if (flat_type === null)
                return false;

            return flat_types.indexOf(flat_type) == flat_type_index;
        }).length;
    },

    _hasFlatTypesOffice : function(flats, floor){
        return flats.map(function (flat) {
            if (floor && flat.floor_id != floor.id)
                return null;

            if (flat.type == 'apartment')
                return null;

            if (flat.type == 'office')
                return 1000;

            return null;
        }).filter(function (flat_type, flat_type_index, flat_types) {
            if (flat_type === null)
                return false;

            return flat_types.indexOf(flat_type) == flat_type_index;
        }).length;
    },

    isAN(value) {
        if(value instanceof Number)
            value = value.valueOf();

        return  isFinite(value) && value === parseInt(value, 10);
    },

    getScrollBarWidth () {
        var $outer = $('<div>').css({visibility: 'hidden', width: 100, overflow: 'scroll'}).appendTo('body'),
            widthWithScroll = $('<div>').css({width: '100%'}).appendTo($outer).outerWidth();
        $outer.remove();
        return 100 - widthWithScroll;
    }
}