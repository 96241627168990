module.exports = {
    render : function(name, parameters) {
        if(parameters !== undefined) {
            parameters.console = function (value) {
                console.log(name + ': ');
                console.log(value);
            };
        }

        return ejs.render($('#' + name + '-template').html(), parameters);
    }
};