export default {
    'index' : {
        'title' : 'Привлеченные партнеры и застройщики',
        'url'   : '#'
    },
    'marketing' : {
        'title' : 'Реферальные ссылки',
        'url'   : '#marketing'
    },
    'payouts' : {
        'title' : 'Выплаты',
        'url'   : '#payouts'
    },
    'banners' : {
        'title' : 'Баннеры',
        'url'   : '#banners'
    },
    'account' : {
        'title' : 'Личные данные',
        'url'   : '#account'
    },
    /*'stat' : {
        'title' : 'Статистика',
        'url'   : '#stat'
    }*/
}