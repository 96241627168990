import niceScroll from 'jquery.nicescroll/dist/jquery.nicescroll.min.js';

import Vars from './../../vars';

import Validation from './../../classes/Validation';
import Request from './../../components/request';
import Utils from './../../components/utils';

import _NAV from './../Const/_NAV';
import _PARAMS from './../Const/_PARAMS';

class Payout {
    constructor(args) {
        let self = this;

        self._setArgs(args);
        self._init();
    }

    _const = {
        entities : 'payouts',
        entity   : 'payout'
    };

    _elems = {
        $content  : $(),
        $cash     : $(),
        $form     : $()
    };

    _args = {
        page : '',
        type : '',
        id   : 0
    };

    _state = {
        items : {},
        item  : {}
    };

    _setArgs = (args) => {
        let self = this;

        self._args.page = args.page ? args.page : '';
        self._args.id   = args.id ? args.id : 0;
    };

    _bindElements = () => {
        let self = this;

        self._elems.$content = !self._elems.$content.length ? $('.content') : self._elems.$content;
        if(!self._elems.$content.length) return;

        self._elems.$cash = $('.cash');

        self._bindUI();
    };

    _bindUI = () => {
        let self = this;

        self._bindApprove();
        self._bindReject();
    };

    _bindApprove = () => {
        let self = this;
        let $approve = self._elems.$content.find('[data-action="approve"]');
        if (!$approve.length) return;

        $approve.click(function(e){
            e.preventDefault();

            loader.increase();
            Request.send({
                path : '/api/partners/' + self._const.entities + '/' + $(this).data('id') + '/approve',
                done : function() {
                    loader.decrease();
                    self._list();
                }
            })
        });
    };

    _bindReject = () => {
        let self = this;
        let $reject = self._elems.$content.find('[data-action="reject"]');
        if (!$reject.length) return;

        $reject.click(function(e){
            e.preventDefault();

            loader.increase();
            Request.send({
                path : '/api/partners/' + self._const.entities + '/' + $(this).data('id') + '/reject',
                data : {
                    comment : $(this).prev('textarea').val()
                },
                done : function() {
                    loader.decrease();
                    self._list();
                }
            })
        });
    };

    _bindForm = () => {
        let self = this;
        if (!self._elems.$form.length) return;

        let validation = new Validation({
            form : self._elems.$form
        });

        self._elems.$form.on('init', function(e){
            e.preventDefault();

            let $form = $(this);

            $form.find('input').on('keyup', function(){
                $form.trigger('update');
            });
            $form.find('input').on('change', function(){
                $form.trigger('update');
            });
            $form.find('input').bind('DOMAutoComplete', function(){
                $form.trigger('update');
            });
            $form.find('select').on('change', function(){
                $form.trigger('update');
            });

            $form.trigger('update');
        });

        self._elems.$form.on('update', function(e){
            e.preventDefault();

            let $form = $(this);

            $form.children('.form-error').hide().remove();

            let $submit = $form.find('[type=submit]');
            if (!$submit.length) return;

            let count = {
                required : 0,
                filled   : 0
            };

            $form.find('input, select').each(function () {
                if ($(this).prop('required') !== undefined && $(this).attr('type') != 'hidden') {
                    count.required++;
                }
                if ($(this).val() != '' && $(this).attr('type') != 'hidden') {
                    count.filled++;
                }
            });

            if (count.filled < count.required) {
                //$submit.attr('disabled', 'disabled');
            }
            else {
                $submit.removeAttr('disabled');
            }
        });

        self._elems.$form.submit(function(e){
            e.preventDefault();
            let $form = $(this);

            let data = {};
            $form.find('input, select').each(function(){
                data[$(this).attr('name')] = $(this).val();
            });

            Request.send({
                path   : '/api/partners/payouts/create',
                data   : data,
                method : $form.attr('method'),
                done   : function(response) {
                    if (response.errors) {
                        validation.showErrors(response.errors);
                        return;
                    }

                    if (response.url) {
                        window.location.href = response.url;
                    }
                    else {
                        window.location.reload();
                    }
                }
            })
        });

        self._elems.$form.trigger('init');
    };

    _form = () => {
        let self = this;

        loader.increase();
        Request.send({
            path : '/api/partners/cash',
            done : function(response) {
                loader.decrease();

                let nav = Utils.renderTemplate('nav', {
                    nav    : _NAV,
                    active : 'payouts'
                });
                let heading = Utils.renderTemplate('heading', {
                    title : 'Заявка на вывод',
                    back  : {
                        title : 'Вернуться к списку',
                        url   : '#payouts'
                    }
                });

                self._elems.$content.html(Utils.renderTemplate('payout-form', {
                    nav     : nav,
                    heading : heading,
                    accrued : response.accrued
                }));

                self._elems.$cash.trigger('update');

                self._elems.$form = self._elems.$content.find('form');
                self._bindForm();
            }
        });
    };

    _list = () => {
        let self = this;

        Request.send({
            path : '/api/partners/' + self._const.entities + '/',
            done : function(response) {
                loader.decrease();
                Utils.scrollToTop();

                self._state.items = response[self._const.entities];
                self._state.item  = {};

                let nav = Utils.renderTemplate('nav', {
                    nav    : _NAV,
                    active : 'payouts'
                });
                let heading = Utils.renderTemplate('heading', {
                    title : 'Выплаты'
                });

                self._elems.$content.html(Utils.renderTemplate(self._const.entities, {
                    nav                    : nav,
                    heading                : heading,
                    [self._const.entities] : self._state.items,
                    _PARAMS                : _PARAMS,
                    isAdmin                : response.is_admin
                }));

                self._elems.$cash.trigger('update');
                self._bindElements();
            },
            fail : function() {
                //window.location.reload();
            }
        });
    };

    _init = () => {
        let self = this;

        if (self._args.page == Vars.page.form) {
            self._bindElements();
            self._form();
        }
        else if (self._args.page == Vars.page.list) {
            self._bindElements();
            self._list();
        }
    }
}

export default Payout;