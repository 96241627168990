import Vars from './../Vars';
import Functions from './../components/functions';
import Utils from './../../components/utils';
import TipWindow from './TipWindow';

class Tip {
    constructor(args) {
        let self = this;
        self._setArgs(args);
        self._init();
    }

    _args = {
        type   : '',
        widget : {}
    };

    _setArgs = (args) => {
        let self = this;

        self._args.type   = args.type;
        self._args.widget = args.widget;

        if (self._args.type == Vars.tip.types.building) {
            self._args.flatType = args.flatType;
            self._args.building = args.building;
            self._args.flats    = args.flats;
            self._args.disabled = args.disabled;
        }
        else if (self._args.type == Vars.tip.types.section) {
            self._args.flatType = args.flatType;
            self._args.building = args.building;
            self._args.section  = args.section;
            self._args.flats    = args.flats;
            self._args.disabled = args.disabled;
        }
        else if (self._args.type == Vars.tip.types.floor) {
            self._args.flatType = args.flatType;
            self._args.object   = args.object;
            self._args.building = args.building;
            self._args.section  = args.section;
            self._args.floor    = args.floor;
            self._args.flats    = args.flats;
        }
        else if (self._args.type == Vars.tip.types.flat) {
            self._args.object   = args.object;
            self._args.building = args.building;
            self._args.section  = args.section;
            self._args.floors   = args.floors;
            self._args.floor    = args.floor;
            self._args.flat     = args.flat;
            self._args.isSale   = args.isSale;
            self._args.isRent   = args.isRent;
        }
        else if (self._args.type == Vars.tip.types.plan) {
            self._args.plan   = args.plan;
            self._args.isSale = args.isSale;
            self._args.isRent = args.isRent;
        }
    };

    _buildingTip = () => {
        let self = this;

        new TipWindow({
            content : Utils.renderTemplate('building-tip', {
                building             : self._args.building,
                flat_types_apartment : self._args.flatType == Vars.flat.types.apartments ? self._flatTypesApartment({
                    flats : self._args.flats,
                    floor : false
                }) : [],
                flat_types_office    : self._args.flatType == Vars.flat.types.offices ? self._flatTypesOffice({
                    flats : self._args.flats,
                    floor : false
                }) : [],
                is_mobile            : Functions._isMobileAny() || Functions._smallDesktop(),
                widget               : self._args.widget,
                disabled             : self._args.disabled
            }),
            entity   : Vars.tip.types.building,
            widget   : self._args.widget
        })
    };

    _sectionTip = () => {
        let self = this;

        new TipWindow({
            content : Utils.renderTemplate('section-tip', {
                building             : self._args.building,
                section              : self._args.section,
                flat_types_apartment : self._args.flatType == Vars.flat.types.apartments || self._args.flatType == Vars.flat.types.all ? self._flatTypesApartment({
                    flats : self._args.flats,
                    floor : false
                }) : [],
                flat_types_office    : self._args.flatType == Vars.flat.types.offices || self._args.flatType == Vars.flat.types.all ? self._flatTypesOffice({
                    flats : self._args.flats,
                    floor : false
                }) : [],
                is_mobile            : Functions._isMobileAny() || Functions._smallDesktop(),
                widget               : self._args.widget,
                disabled             : self._args.disabled
            }),
            entity  : Vars.tip.types.section,
            widget  : self._args.widget
        })
    };

    _floorTip = () => {
        let self = this;

        new TipWindow({
            content : Utils.renderTemplate('floor-tip', {
                object               : self._args.object,
                building             : self._args.building,
                section              : self._args.section,
                floor                : self._args.floor,
                flatType             : self._args.flatType,
                flat_types_apartment : self._args.flatType == Vars.flat.types.apartments || self._args.flatType == Vars.flat.types.all ? self._flatTypesApartment({
                    flats  : self._args.flats,
                    floor  : self._args.floor,
                    object : self._args.object
                }) : [],
                flat_types_office    : self._args.flatType == Vars.flat.types.offices || self._args.flatType == Vars.flat.types.all ? self._flatTypesOffice({
                    flats  : self._args.flats,
                    floor  : self._args.floor,
                    object : self._args.object
                }) : [],
                is_mobile            : Functions._isMobileAny() || Functions._smallDesktop(),
                widget               : self._args.widget
            }),
            entity  : Vars.tip.types.floor,
            widget  : self._args.widget
        })
    };

    _flatTip = () => {
        let self = this;

        new TipWindow({
            content : Utils.renderTemplate('flat-tip', {
                title                : self._args.flat.type == 'office' ? 'Коммерческое помещение' : (
                    self._args.flat.count_of_rooms == 0
                        ? 'Студия'
                        : (
                            self._args.building.is_apartamentable
                                ? self._args.flat.count_of_rooms + '-комнатные апартаменты'
                                : self._args.flat.count_of_rooms + '-комн. кв.'
                        )
                ),
                object               : self._args.object,
                building             : self._args.building,
                section              : self._args.section,
                floors               : self._args.floors,
                floor                : self._args.floor,
                flat                 : self._args.flat,
                is_sale              : self._args.isSale,
                is_rent              : self._args.isRent,
                widget               : self._args.widget,
                is_mobile            : Functions._isMobileAny() || Functions._smallDesktop(),
            }),
            entity  : Vars.tip.types.flat,
            widget  : self._args.widget
        });
    };

    _planTip = () => {
        let self = this;

        new TipWindow({
            content : Utils.renderTemplate('plan-tip', {
                title     : self._args.plan.name,
                plan      : self._args.plan,
                is_sale   : self._args.isSale,
                is_rent   : self._args.isRent,
                is_mobile : Functions._isMobileAny() || Functions._smallDesktop(),
            }),
            entity  : Vars.tip.types.plan,
            widget  : self._args.widget
        });
    };

    _flatTypesApartment = (args) => {
        let flats  = args.flats;
        let floor  = args.floor;
        let object = args.object;

        return flats.filter(function(flat){
            return flat.status == 'available';
        }).map(function(flat) {
            if (floor && flat.group) {
                let mFlats = object.flats.filter(function(f) {
                    return f.group;
                }).filter(function(f){
                    return f.group_id == flat.group_id;
                });
                if (!mFlats.filter(function(flat){ return flat.floor_id == floor.id; }).length) return null;
                else {
                    if (['flat', 'apartment'].indexOf(flat.type) !== -1)return mFlats.filter(function(flat){ return flat.floor_id == floor.id; })[0].count_of_rooms;
                    if (flat.type == 'office') return null;

                }
            }
            else if(floor && flat.floor_id != floor.id) return null;

            if (['flat', 'apartment'].indexOf(flat.type) !== -1) return flat.count_of_rooms;
            if (flat.type == 'office') return null;

            return null;
        }).filter(function(flat_type, flat_type_index, flat_types) {
            if (flat_type === null) return false;
            return flat_types.indexOf(flat_type) == flat_type_index;
        }).sort(function(flat_type0, flat_type1) {
            return flat_type0 - flat_type1;
        }).map(function(flat_type) {
            let type_flats = flats.filter(function(flat) {
                if (floor && flat.group) {
                    let mFlats = object.flats.filter(function(f) {
                        return f.group;
                    }).filter(function(f){
                        return f.group_id == flat.group_id;
                    });
                    if (!mFlats.filter(function(flat){ return flat.floor_id == floor.id; }).length) return false;
                }
                else if (floor && flat.floor_id != floor.id) return false;
                if (flat.status != 'available') return false;
                if (flat.type == 'office') return false;

                return ['flat', 'apartment'].indexOf(flat.type) !== -1 && flat.count_of_rooms == flat_type;
            });

            return {
                name: 	flat_type,
                count: 	type_flats.length,

                area: type_flats.reduce(function(area, type_flat) {
                    if (!area)
                        return [ type_flat.total_area, type_flat.total_area ];

                    if (area[0] > type_flat.total_area)
                        area[0] = type_flat.total_area;

                    if (area[1] < type_flat.total_area)
                        area[1] = type_flat.total_area;

                    return area;
                }, null),

                price: type_flats.filter(function(flat) { return flat.price; }).reduce(function(price, type_flat) {
                    if(!type_flat.is_sale)
                        return;

                    if (!type_flat.price)
                        return;

                    if (type_flat.price == 0.0)
                        return price;

                    if (!price)
                        return [ type_flat.price, type_flat.price ];

                    if (price[0] > type_flat.price)
                        price[0] = type_flat.price;

                    if (price[1] < type_flat.price)
                        price[1] = type_flat.price;

                    return price;
                }, null),

                price_per_square_meter: type_flats.filter(function(flat) { return flat.price_per_square_meter; }).reduce(function(price_per_square_meter, type_flat) {
                    if(!type_flat.is_sale)
                        return;

                    if (type_flat.price_per_square_meter == 0.0)
                        return price_per_square_meter;

                    if (!price_per_square_meter)
                        return [ type_flat.price_per_square_meter, type_flat.price_per_square_meter ];

                    if (price_per_square_meter[0] > type_flat.price_per_square_meter)
                        price_per_square_meter[0] = type_flat.price_per_square_meter;

                    if (price_per_square_meter[1] < type_flat.price_per_square_meter)
                        price_per_square_meter[1] = type_flat.price_per_square_meter;

                    return price_per_square_meter;
                }, null),

                monthly_price: type_flats.filter(function(flat) { return flat.monthly_price; }).reduce(function(monthly_price, type_flat) {
                    if(!type_flat.is_rent)
                        return;

                    if (type_flat.monthly_price == 0.0)
                        return monthly_price;

                    if (!monthly_price)
                        return [ type_flat.monthly_price, type_flat.monthly_price ];

                    if (monthly_price[0] > type_flat.monthly_price)
                        monthly_price[0] = type_flat.monthly_price;

                    if (monthly_price[1] < type_flat.monthly_price)
                        monthly_price[1] = type_flat.monthly_price;

                    return monthly_price;
                }, null),

                monthly_price_per_square_meter: type_flats.filter(function(flat) { return flat.monthly_price_per_square_meter; }).reduce(function(monthly_price_per_square_meter, type_flat) {
                    if(!type_flat.is_rent)
                        return;

                    if (type_flat.monthly_price_per_square_meter == 0.0)
                        return monthly_price_per_square_meter;

                    if (!monthly_price_per_square_meter)
                        return [ type_flat.monthly_price_per_square_meter, type_flat.monthly_price_per_square_meter ];

                    if (monthly_price_per_square_meter[0] > type_flat.monthly_price_per_square_meter)
                        monthly_price_per_square_meter[0] = type_flat.monthly_price_per_square_meter;

                    if (monthly_price_per_square_meter[1] < type_flat.monthly_price_per_square_meter)
                        monthly_price_per_square_meter[1] = type_flat.monthly_price_per_square_meter;

                    return monthly_price_per_square_meter;
                }, null)
            };
        });
    };

    _flatTypesOffice = (args) => {
        let flats  = args.flats;
        let floor  = args.floor;
        let object = args.object;

        return flats.filter(function(flat){
            return flat.status == 'available';
        }).map(function (flat) {
            if (floor && flat.group) {
                let mFlats = object.flats.filter(function(f) {
                    return f.group;
                }).filter(function(f){
                    return f.group_id == flat.group_id;
                });
                if (!mFlats.filter(function(flat){ return flat.floor_id == floor.id; }).length) return null;
                else {
                    if (['flat', 'apartment'].indexOf(flat.type) !== -1) return null;
                    if (flat.type == 'office') return 1000;
                }
            }
            else if (floor && flat.floor_id != floor.id) return null;

            if (['flat', 'apartment'].indexOf(flat.type) !== -1) return null;
            if (flat.type == 'office') return 1000;

            return null;
        }).filter(function (flat_type, flat_type_index, flat_types) {
            if (flat_type === null) return false;
            return flat_types.indexOf(flat_type) == flat_type_index;
        }).sort(function (flat_type0, flat_type1) {
            return flat_type0 - flat_type1;
        }).map(function (flat_type) {
            let type_flats = flats.filter(function (flat) {
                if (floor && flat.group) {
                    let mFlats = object.flats.filter(function(f) {
                        return f.group;
                    }).filter(function(f){
                        return f.group_id == flat.group_id;
                    });
                    if (!mFlats.filter(function(f){ return f.floor_id == floor.id; }).length) return false;
                }
                else if (floor && flat.floor_id != floor.id) return false;
                if (flat.status != 'available') return false;
                if (['flat', 'apartment'].indexOf(flat.type) !== -1) return false;

                if (flat_type == 1000) return flat.type == 'office';

                return flat.type == 'office' && flat.count_of_rooms == flat_type;
            });

            return {
                name: flat_type,
                count: type_flats.length,

                area: type_flats.reduce(function (area, type_flat) {
                    if (!area)
                        return [type_flat.total_area, type_flat.total_area];

                    if (area[0] > type_flat.total_area)
                        area[0] = type_flat.total_area;

                    if (area[1] < type_flat.total_area)
                        area[1] = type_flat.total_area;

                    return area;
                }, null),

                price: type_flats.reduce(function (price, type_flat) {
                    if (type_flat.price == 0.0)
                        return price;

                    if (!price)
                        return [type_flat.price, type_flat.price];

                    if (price[0] > type_flat.price)
                        price[0] = type_flat.price;

                    if (price[1] < type_flat.price)
                        price[1] = type_flat.price;

                    return price;
                }, null),

                price_per_square_meter: type_flats.reduce(function (price_per_square_meter, type_flat) {
                    if (type_flat.price_per_square_meter == 0.0)
                        return price_per_square_meter;

                    if (!price_per_square_meter)
                        return [type_flat.price_per_square_meter, type_flat.price_per_square_meter];

                    if (price_per_square_meter[0] > type_flat.price_per_square_meter)
                        price_per_square_meter[0] = type_flat.price_per_square_meter;

                    if (price_per_square_meter[1] < type_flat.price_per_square_meter)
                        price_per_square_meter[1] = type_flat.price_per_square_meter;

                    return price_per_square_meter;
                }, null),

                monthly_price: (flat_type == 1000 ? type_flats.reduce(function (monthly_price, type_flat) {
                    if (type_flat.monthly_price == 0.0)
                        return monthly_price;

                    if (!monthly_price)
                        return [type_flat.monthly_price, type_flat.monthly_price];

                    if (monthly_price[0] > type_flat.monthly_price)
                        monthly_price[0] = type_flat.monthly_price;

                    if (monthly_price[1] < type_flat.monthly_price)
                        monthly_price[1] = type_flat.monthly_price;

                    return monthly_price;
                }, null) : null),

                monthly_price_per_square_meter: (flat_type == 1000 ? type_flats.reduce(function (monthly_price_per_square_meter, type_flat) {
                    if (type_flat.monthly_price_per_square_meter == 0.0)
                        return monthly_price_per_square_meter;

                    if (!monthly_price_per_square_meter)
                        return [type_flat.monthly_price_per_square_meter, type_flat.monthly_price_per_square_meter];

                    if (monthly_price_per_square_meter[0] > type_flat.monthly_price_per_square_meter)
                        monthly_price_per_square_meter[0] = type_flat.monthly_price_per_square_meter;

                    if (monthly_price_per_square_meter[1] < type_flat.monthly_price_per_square_meter)
                        monthly_price_per_square_meter[1] = type_flat.monthly_price_per_square_meter;

                    return monthly_price_per_square_meter;
                }, null) : null),
            };
        });
    };

    _init = () => {
        let self = this;

        if (self._args.type == Vars.tip.types.building) {
            self._buildingTip()
        }
        else if (self._args.type == Vars.tip.types.section) {
            self._sectionTip()
        }
        else if (self._args.type == Vars.tip.types.floor) {
            self._floorTip()
        }
        else if (self._args.type == Vars.tip.types.flat) {
            self._flatTip()
        }
        else if (self._args.type == Vars.tip.types.plan) {
            self._planTip()
        }
    }
}

export default Tip;