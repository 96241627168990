import Vars from './../Vars';
import Functions from './../components/functions';
import Utils from './../../components/utils';

class TipWindow {
    constructor(args) {
        let self = this;
        self._setArgs(args);
        self._init();
    };

    _elems = {
        $_ : $()
    };

    _state = {
        clientX : 0,
        clientY : 0
    };

    _args = {
        widget  : {},
        content : '',
        entity  : ''
    };

    _setArgs = (args) => {
        let self = this;

        self._args.widget  = args.widget;
        self._args.content = args.content;
        self._args.entity  = args.entity;
    };

    _render = () => {
        let self = this;

        self._elems.$_ = $('<div />').addClass('ifacade-tip__window tip-window ' +  self._args.entity).html(Utils.renderTemplate('tip-window'));
        self._elems.$_.find('.tip-window__wrapper').html(self._args.content || 'Содержимое окна-подсказки не установлено');

        if (Object.keys(self._args.widget).length) {
            let $wrapper =
                $('<div />')
                    .addClass('ifacade-tip--widget')
                    .addClass('tip-widget-' + self._args.widget.widget_id)
                    .attr('id', 'ifacade-tip-widget-' + self._args.widget.widget_id)
                    .appendTo('body');

            self._elems.$_.appendTo($wrapper);
        } else {
            self._elems.$_.appendTo('body');
        }

        if(Functions._isMobileAny()) {
            self._elems.$_.addClass('tip-window--is-mobile');
            self._elems.$_.append(
                $('<a/>')
                    .attr('href', '#')
                    .addClass('tip-window__close')
                    .append(
                        $('<label/>')
                            .text('закрыть')
                    )
            )
        }
    };

    _bindUI = () => {
        let self = this;

        self._elems.$_.click(function(e) {
            if ($(e.target).closest('.tip-window__close').length) {
                e.preventDefault();
                self.close();
                return;
            }

            if (!$(e.target).closest('.ifacade-tip__window').length) {
                e.preventDefault();
                self.close();
                return;
            }
        });

        if(!Functions._isMobileAny() && !Functions._smallDesktop()) {
            $(window).unbind('mousemove').on('mousemove', function(e){
                if (self) self._updatePosition(e);
            });
        }

        self._updatePosition();
    };

    _updatePosition = (e) => {
        let self = this;

        if (e && !Functions._smallDesktop()) {
            self._state.clientY = e.clientY;
            self._state.clientX = e.clientX;
        }
        else {
            self._state.clientY = $(window).height() / 2;
            self._state.clientX = $(window).width() / 2;
        }

        let top  = self._state.clientY - self._elems.$_.height() / 2;
        let left = e !== undefined && !Functions._smallDesktop() ?
            (
                (
                    self._state.clientX < $(window).width() / 2 && self._state.clientX > self._elems.$_.width() + 30
                ) ? (
                    self._state.clientX - self._elems.$_.width() - 30
                ) : (
                    self._state.clientX  > $(window).width() - self._elems.$_.width() - 30
                        ? self._state.clientX - self._elems.$_.width() - 30
                        : self._state.clientX + 30
                )
            )
            : self._state.clientX - self._elems.$_.width() / 2;

        let fromLeft =
            (
                self._state.clientX < $(window).width() / 2 && left + self._elems.$_.width() + 10 < self._state.clientX
                ||
                self._state.clientX  > $(window).width() - self._elems.$_.width() - 30
            ) ? true : false;

        if (top + self._elems.$_.height() + 10 > $(window).height()) {
            top = $(window).height() - self._elems.$_.height() - 10;
        }

        if(e !== undefined && $(window).width >= self._elems.$_.width()*2) {
            if (left + self._elems.$_.width() + 10 > $(window).width() && left + self._elems.$_.width() + 10 > self._states.clientX) {
                left = $(window).width() - self._elems.$_.width() - 10;
            }
            else if (left - 10 < 0) {
                left = 10;
            }
        }

        if (e !== undefined && !Functions._smallDesktop()) {
            if (fromLeft) {
                self._elems.$_.addClass('lefting').removeClass('righting');
            }
            else {
                self._elems.$_.addClass('righting').removeClass('lefting');
            }
        }

        if (e == undefined && !Functions._isTouch()) return;

        self._elems.$_.css({
            top   : top + 'px',
            left  : left + 'px',
            right : ''
        });
    };

    _init = () => {
        let self = this;

        window.tipWindow && tipWindow.close();

        self._render();
        self._bindUI();

        window.tipWindow = self;
    };

    close = () => {
        let self = this;

        if (Object.keys(self._args.widget).length) {
            self._elems.$_.parent().remove();
        }
        else {
            self._elems.$_.remove();
        }

        $(window).off('mousemove', self._updatePosition);
        window.tipWindow = null; self = null;
    }
};

export default TipWindow;